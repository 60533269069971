import React from "react";
import "../../styles/nobookings.css";

const NoBookings = () => {
  return (
    <div className="nobookings">
      <p>Désolé , Il n'y a pas de reservations pour le moment :-(</p>
    </div>
  );
};

export default NoBookings;
